.hamburger {
  display: block;
  justify-self: flex-end;
  width: 22px;
  aspect-ratio: 1;
  padding: 0;
  margin: 8px;
  border: none;
  background: transparent url('../../images/hamburger.svg');
  background-size: cover;
}

@media screen and (min-width: 768px) {
  .hamburger {
    width: 28px;
    margin: 5px;
  }
}

@media screen and (min-width: 1024px) {
  .hamburger {
    display: none;
  }
}
