.profile {
  /* min-height: calc(100vh - 74px - 40px); */
  min-height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile__title {
  margin: 70px 30px 63px;
  padding: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.profile__form {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  margin-bottom: 40px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.profile__inputs {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
}

.profile__label {
  margin: 0;
  padding: 13px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #424242;
  font-family: inherit;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  transition: border-bottom 0.5s ease;
}

.profile__label:last-of-type {
  border-bottom: none;
}

.profile__label:focus-within {
  border-bottom: 1px solid #4285f4;
}

.profile__input {
  flex-grow: 1;
  margin: 0;
  padding: 4px 0;
  border: none;
  border-radius: 0;
  display: block;
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-weight: 400;
  text-align: right;
}

.profile__input_error {
  color: #ee3465;
}

.profile__input:focus {
  outline: none;
  background: transparent;
}

.profile__error {
  margin: 0;
  padding: 4px 0;
  box-sizing: border-box;
  max-width: 260px;
  font-size: 11px;
  line-height: 13px;
  color: #ee3465;
}

.profile__button {
  align-self: center;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #424242;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  transition:
    color 0.5s ease,
    opacity 0.1s ease;
}

.profile__button_active {
  color: white;
}

.profile__button_active:hover {
  opacity: 0.8;
  cursor: pointer;
}

.profile__button_type_logout {
  font-weight: 500;
  color: #ee3465;
}

@media screen and (min-width: 768px) {
  .profile {
    min-height: min(742px, calc(100vh - 74px));
  }

  .profile__title {
    margin-top: 236px;
    margin-bottom: 79px;
  }

  .profile__form {
    min-width: 410px;
  }

  .profile__error {
    max-width: 410px;
  }

  .profile__button {
    font-size: 13px;
    line-height: 16px;
  }
}
@media screen and (min-width: 1280px) {
  .profile {
    min-height: min(596px, calc(100vh - 74px));
  }

  .profile__title {
    margin-top: 74px;
    margin-bottom: 106px;
  }

  .profile__form {
    min-width: 410px;
  }
}
