.card-delete-button {
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  aspect-ratio: 1;
  border: none;
  border-radius: 50%;
  background-color: #313131;
  background-image: url('../../images/close.svg');
  background-size: 8px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 0.1s ease;
}

.card-delete-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.card-delete-button:focus {
  outline: 1px solid #8b8b8b;
}
