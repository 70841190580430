.credentials-screen {
  margin: 0;
  padding: 0 30px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.credentials-screen__title-container {
  margin: 0;
  padding: 56px 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.credentials-screen__title {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.credentials-screen__form {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.credentials-screen__inputs {
  flex-grow: 1;
  margin: 0;
  padding: 0 0 59px;
  display: flex;
  flex-direction: column;
  border: none;
}

.credentials-screen__submit-button {
  margin: 14px 0;
  padding: 15px 0;
  background-color: #8b8b8b;
  border: none;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  transition:
    background-color 0.5s ease,
    color 0.5s ease,
    opacity 0.1s ease;
}

.credentials-screen__submit-button_active {
  background-color: #4285f4;
}

.credentials-screen__submit-button_active:hover {
  opacity: 0.8;
  cursor: pointer;
}

.credentials-screen__extra {
  align-self: center;
  margin: 0;
  padding: 0;
  padding-bottom: 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8b8b8b;
}

.credentials-screen__auth-link {
  color: #4285f4;
}

@media screen and (min-width: 768px) {
  .credentials-screen {
    justify-content: center;
    align-items: center;
  }

  .credentials-screen__title-container {
    align-items: flex-start;
    min-width: 396px;
    padding: 30px 0 40px;
    gap: 40px;
  }

  .credentials-screen__form {
    flex-grow: 0;
    min-width: 396px;
    min-height: 380px;
  }

  .credentials-screen__submit-button {
    font-size: 14px;
    line-height: 17px;
  }

  .credentials-screen__extra {
    font-size: 14px;
    line-height: 17px;
  }
}
