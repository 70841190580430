.about-project__cards {
  margin: 0;
  padding: 0;
  padding-top: 60px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.about-project__card {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.about-project__card-title {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.04em;
}

.about-project__card-text {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}

.about-project__timeline {
  margin: 0;
  padding: 0;
  padding-top: 60px;
  list-style: none;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
}

.about-project__stage-1 {
  min-width: 100px;
  flex-grow: 1;
}

.about-project__stage-2 {
  flex-grow: 5;
}

.about-project__stage-term {
  margin: 0;
  padding: 11px 0;
  background-color: #303030;
}

.about-project__stage-term_highlight {
  color: black;
  background-color: #3ddc84;
}

.about-project__stage-caption {
  margin: 0;
  padding: 0;
  padding-top: 10px;
  color: #a0a0a0;
}

@media screen and (min-width: 768px) {
  .about-project__cards {
    padding-top: 70px;
    flex-direction: row;
    gap: 30px;
  }

  .about-project__card {
    gap: 22px;
  }

  .about-project__card-title {
    font-size: 20px;
  }

  .about-project__card-text {
    font-size: 12px;
    line-height: 18px;
  }

  .about-project__timeline {
    padding-top: 93px;
    font-size: 14px;
    line-height: 17px;
  }

  .about-project__stage-term {
    padding: 9px 0;
  }

  .about-project__stage-caption {
    padding-top: 14px;
  }
}

@media screen and (min-width: 1280px) {
  .about-project__cards {
    gap: 40px;
  }

  .about-project__card {
    gap: 26px;
  }

  .about-project__card-text {
    font-size: 14px;
    line-height: 20px;
  }

  .about-project__timeline {
    padding-top: 110px;
  }
}
