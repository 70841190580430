.portfolio {
  display: flex;
  flex-direction: column;
  padding: 0 14px 51px;
}

.portfolio__title {
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #a0a0a0;
}

.portfolio__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.portfolio__element {
  margin: 0;
  padding: 0;

  border-bottom: 1px solid #e6e6e6;
}

.portfolio__element:last-of-type {
  border-bottom: none;
}

.portfolio__link {
  margin: 0;
  padding: 20px 0 19px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.04em;
  color: white;
}

.portfolio__icon {
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .portfolio {
    padding: 0 50px 71px;
  }

  .portfolio__title {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 20px;
  }

  .portfolio__link {
    font-size: 28px;
    line-height: 50px;
  }
}

@media screen and (min-width: 1280px) {
  .portfolio {
    padding: 0 70px 106px;
  }

  .portfolio__title {
    font-size: 18px;
    line-height: 20px;
  }

  .portfolio__link {
    font-size: 30px;
    line-height: 50px;
  }
}
