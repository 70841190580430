.logo {
  margin: 0;
  padding: 0;
  width: 38px;
  height: 38px;
}

.logo__icon {
  width: 100%;
  height: 100%;
}
