.nav-tab {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
}

.nav-tab__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 6px;
}

.nav-tab__link {
  display: block;
  text-align: center;
  padding: 5px 0;
  min-width: 82px;
  color: white;
  background: #303030;
  border-radius: 6px;
}

@media screen and (min-width: 768px) {
  .nav-tab {
    font-size: 12px;
  }

  .nav-tab__list {
    gap: 10px;
  }

  .nav-tab__link {
    padding: 10px 0;
    min-width: 96px;
    border-radius: 10px;
  }
}
