.main {
  display: flex;
  flex-direction: column;
  align-items: justify;
}

.main__section {
  display: flex;
  flex-direction: column;
  padding: 70px 14px;
}

.main__section-title {
  padding: 0;
  padding-bottom: 28px;
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: -0.04em;
  border-bottom: 1px solid #dadada;
}

@media screen and (min-width: 768px) {
  .main__section {
    padding: 90px 50px;
  }

  .main__section-title {
    font-size: 22px;
    padding-bottom: 23px;
  }
}

@media screen and (min-width: 1280px) {
  .main__section {
    padding: 110px 70px;
  }
}
