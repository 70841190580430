.not-found {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.not-found__container {
  min-height: calc(50vh + 61px);
  margin: 0;
  margin-top: auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.not-found__title {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 80px;
  line-height: 97px;
}

.not-found__text {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.not-found__back-button {
  margin: 0;
  margin-top: auto;
  margin-bottom: 30px;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4285f4;
  background: transparent;
  border: none;
}

@media screen and (min-width: 768px) {
  .not-found__container {
    min-height: calc(50vh + 104px);
    gap: 5px;
  }

  .not-found__title {
    font-size: 140px;
    line-height: 169px;
  }

  .not-found__text {
    font-size: 16px;
    line-height: 19px;
  }

  .not-found__back-button {
    margin-top: 184px;
    font-size: 14px;
    line-height: 17px;
  }
}
