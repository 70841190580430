.promo {
  margin: 0;
  padding: 14px 14px 0;
  display: flex;
  flex-direction: column;
}

.promo__banner {
  margin: 0;
  padding: 28.2vh 0 17px;
  box-sizing: border-box;
  min-height: calc(100vh - 194px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* gap: 212px; */
  gap: 30px;
  background-color: #272727;
  border-radius: 10px;
}

.promo__title {
  margin: 0;
  padding: 0;
  max-width: 680px;
  font-weight: 400;
  font-size: 29px;
  line-height: 1.3;
  text-align: center;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 768px) {
  .promo {
    padding: 20px 20px 0;
  }

  .promo__banner {
    padding: 34.2vh 0 30px;
    min-height: calc(100vh - 190px);
  }

  .promo__title {
    font-size: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .promo {
    padding-left: 40px;
    padding-right: 40px;
  }

  .promo__banner {
    padding-top: 22.9vh;
    min-height: calc(100vh - 207px);
  }

  .promo__title {
    max-width: 730px;
    font-size: 50px;
  }
}
