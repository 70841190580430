.about-me__profile {
  margin: 0;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.about-me__image {
  width: 100%;
  border-radius: 10px;
}

.about-me__article {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.about-me__title {
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.04em;
}

.about-me__subtitle {
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}

.about-me__text {
  flex-grow: 1;
  margin: 0;
  margin-bottom: 40px;
  padding: 0;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
}

.about-me__link {
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

@media screen and (min-width: 768px) {
  .about-me__profile {
    padding-top: 66px;
    flex-direction: row-reverse;
    align-items: start;
    gap: 49px;
  }

  .about-me__image {
    max-width: 255px;
  }

  .about-me__title {
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 40px;
  }

  .about-me__subtitle {
    font-size: 12px;
    line-height: 18px;
  }

  .about-me__text {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 18px;
  }
}
@media screen and (min-width: 1280px) {
  .about-me__profile {
    gap: 270px;
  }

  .about-me__image {
    max-width: 270px;
  }

  .about-me__title {
    margin-bottom: 18px;
    font-size: 50px;
    line-height: 58px;
  }

  .about-me__subtitle {
    margin-bottom: 26px;
    font-size: 18px;
    line-height: 20px;
  }

  .about-me__text {
    font-size: 14px;
    line-height: 22px;
  }
}
