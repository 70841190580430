.card-bookmark-button {
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  aspect-ratio: 1;
  border: none;
  border-radius: 50%;
  background-color: #313131;
  background-image: url('../../images/bookmark.svg');
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  transition:
    opacity 0.1s ease,
    background-color 0.2s ease,
    background-image 0.2s ease;
}

.card-bookmark-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.card-bookmark-button:focus {
  outline: 1px solid #8b8b8b;
}

.card-bookmark-button_active {
  background-color: #3ddc84;
  background-image: url('../../images/bookmark-active.svg');
}
