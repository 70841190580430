.techs {
  transition: 0.5s background ease;
}

.techs__title {
  align-self: center;
  margin: 0;
  padding: 60px 0 24px;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.04em;
}

.techs__text {
  align-self: center;
  margin: 0;
  padding: 0;
  padding-bottom: 50px;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: -0.04em;
  text-align: center;
}

.techs__list {
  justify-content: center;
  margin: 0;
  padding: 0 57px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 84px);
  gap: 10px;
  list-style: none;
}

.techs__element {
  background-color: #303030;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 57px;
}

.techs__tech {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 768px) {
  .techs {
    background-color: #272727;
  }

  .techs__title {
    padding: 80px 0 22px;
    font-size: 50px;
    line-height: 58px;
  }
  .techs__text {
    padding-bottom: 83px;
    max-width: 460px;
    font-size: 12px;
    line-height: 18px;
  }
  .techs__list {
    padding: 0;
  }
}

@media screen and (min-width: 1280px) {
  .techs__title {
    padding: 90px 0 26px;
  }

  .techs__text {
    padding-bottom: 100px;
    font-size: 14px;
    line-height: 20px;
  }

  .techs__list {
    grid-template-columns: repeat(auto-fit, 90px);
  }

  .techs__element {
    min-height: 60px;
  }

  .techs__tech {
    font-size: 14px;
    line-height: 17px;
  }
}
