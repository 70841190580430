.input {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #8b8b8b;
}

.input__field {
  margin: 0;
  padding: 5px 0 10px;
  background-color: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #424242;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  transition: border-bottom 0.5s ease;
}

.input__field_error {
  color: #ee3465;
}

.input__field:focus {
  outline: none;
  border-bottom: 1px solid #4285f4;
}

.input__error {
  margin: 0;
  padding: 6px 0;
  box-sizing: border-box;
  min-height: 24px;
  max-width: 260px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ee3465;
}

@media screen and (min-width: 768px) {
  .input__error {
    max-width: 396px;
  }
}
