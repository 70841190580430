.app {
  padding: 0;
  margin: 0 auto;
  min-width: 320px;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', 'Helvetica', sans-serif;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}

.app__link {
  text-decoration: none;
  transition: opacity 0.1s ease;
}

.app__link:hover {
  opacity: 0.7;
  cursor: pointer;
}

.app__link:focus {
  outline: 1px solid #8b8b8b;
}

.app__button {
  transition: opacity 0.1s ease;
}

.app__button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.app__button:focus {
  outline: 1px solid #8b8b8b;
}
