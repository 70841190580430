.movie-card {
  margin: 0;
  padding: 0;
  min-width: 0;
  background-color: #222222;
}

.movie-card__link {
  min-width: 0;
  display: flex;
  flex-direction: column;
  background-color: #222222;
  color: white;
}

.movie-card__container {
  min-width: 0;
  margin: 0;
  padding: 14px;
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.movie-card__description {
  flex-grow: 1;
  margin: 0;
  padding: 0;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.movie-card__title {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.movie-card__duration {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #8b8b8b;
}

.movie-card__image {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

@media screen and (min-width: 1024px) {
  .movie-card__container {
    gap: 26px;
  }
}
