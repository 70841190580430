.api-info {
  align-self: center;
  margin: 0;
  padding: 0;
  min-height: 14px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
}

.api-info_type_error {
  color: #ee3465;
}

.api-info_type_success {
  color: white;
}
