.search-form {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: none;
}

.search-form__search {
  flex-grow: 1;
  margin: 0;
  padding: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 46px;
  background-color: #2f2f2f;
  border: none;
  border-radius: 9px;
}

.search-form__input {
  display: block;
  margin: 0;
  padding: 7px 0;
  flex-grow: 1;
  border: none;
  border-radius: 0;
  background: transparent;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
}

.search-form__input::placeholder {
  font-family: inherit;
  color: #8b8b8b;
}

.search-form__input:focus {
  outline: none;
  margin-bottom: -1px;
  border-bottom: 1px solid #8b8b8b;
}

.search-form__submit-button {
  margin: 0;
  padding: 0;
  width: 34px;
  height: 34px;
  border: none;
  border-radius: 50%;
  background-color: #4285f4;
  background-image: url('../../images/search-button-icon.svg');
  background-position: center;
  background-repeat: no-repeat;
  transition:
    opacity 0.1s ease,
    background-color 0.2s ease;
}

.search-form__submit-button_disabled {
  background-color: #8b8b8b;
}

.search-form__submit-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.search-form__submit-button_disabled:hover {
  opacity: 1;
  cursor: unset;
}

.search-form__filter {
  align-self: center;
  margin: 0;
  padding: 45px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
  border: none;
}

.search-form__filter-name {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
}

@media screen and (min-width: 768px) {
  .search-form {
    flex-direction: row;
    background-color: #2f2f2f;
    border: none;
    border-radius: 9px;
  }

  .search-form__search {
    padding-left: 63px;
    background-color: transparent;
    background-image: url('../../images/search-icon.svg');
    background-position: top 50% left 25px;
    background-repeat: no-repeat;
  }

  .search-form__input {
    font-size: 18px;
    line-height: 20px;
  }

  .search-form__filter {
    padding: 7px 20px;
    border-left: 1px solid #505050;
  }

  .search-form__filter-name {
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: 13px;
    line-height: 18px;
  }
}

@media screen and (min-width: 1280px) {
  .search-form__search {
    padding-right: 30px;
  }

  .search-form__filter {
    padding-left: 30px;
    padding-right: 30px;
  }
}
