.movies {
  display: flex;
  flex-direction: column;
  align-items: justify;
}

.movies__section {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.movies__info-container {
  margin: 0;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.movies__info-text {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.movies__info-text_type_error {
  color: #ee3465;
}

.movies__search-form {
  padding-top: 80px;
  border-bottom: 1px solid #424242;
}

.movies__show-more-button {
  align-self: center;
  margin: 50px 0 80px;
  padding: 11px 20px 9px;
  min-width: 240px;
  background-color: transparent;
  border: 1px solid #424242;
  border-radius: 6px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.movies__spacer {
  height: 140px;
}

@media screen and (min-width: 768px) {
  .movies__section {
    margin: 0 30px;
  }

  .movies__search-form {
    padding-bottom: 110px;
  }

  .movies__show-more-button {
    min-width: 320px;
  }
}

@media screen and (min-width: 1280px) {
  .movies__section {
    margin: 0 70px;
  }

  .movies__search-form {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
